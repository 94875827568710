export default {
  TopBarSHSB: require('../assets/images/TopBarSHSB.jpg'),
  SHAppIcon: require('../assets/images/SHAppIcon.png'),
  ArchivesDatabaseButton: require('../assets/images/ArchivesDatabaseButton.png'),
  ContactButton: require('../assets/images/ContactButton.png'),
  CloseButton: require('../assets/images/CloseButton.png'),
  LibraryDatabaseButton: require('../assets/images/LibraryDatabaseButton.png'),
  MapButton: require('../assets/images/MapButton.png'),
  MuseumDatabaseButton: require('../assets/images/MuseumDatabaseButton.png'),
  OnlineResourcesButton: require('../assets/images/OnlineResourcesButton.png'),
  PlacesTour: require('../assets/images/PlacesTour.png'),
  ReproButton: require('../assets/images/ReproButton.png'),
  ResearchButton: require('../assets/images/ResearchButton.png'),
  TermsButton: require('../assets/images/TermsButton.png'),
  ResourcesButton: require('../assets/images/ResourcesButton.png'),
  SHSTitleMobile: require('../assets/images/SHSTitleMobile.jpg'),
  PeopleTour: require('../assets/images/PeopleTour.png'),
  SCMLogo: require('../assets/images/SCMLogo.png'),
};
